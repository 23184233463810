.container {
  position: relative;
  vertical-align: bottom;
  box-shadow: 0 16px 20px 0 rgba(0, 30, 60, 0.08);
  border-radius: var(--radius-large);
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: var(--color-surface-solid-light-lighter);
  padding: 24px;
  height: 335px;
  overflow: hidden;
  color: var(--color-primary);
  text-decoration: none;
}

@media (width >= 650px) {
  .container {
    padding: 32px;
    height: 375px;
  }
}

.kicker-wrapper {
  --kicker-font-size: var(--font-size-12px);
  --kicker-line-height: 1.33;
}

@media (width >= 650px) {
  .kicker-wrapper {
    --kicker-font-size: var(--font-size-16px);
    --kicker-line-height: 1.5;
  }
}

.container_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.text-large {
  margin-bottom: 15px;
  font-weight: bold;
  font-size: var(--font-size-45px);
  line-height: 1;
}

@media (width >= 650px) {
  .text-large {
    font-size: var(--font-size-70px);
    line-height: 0.92;
  }
}

@media (width >= 1200px) {
  .text-large {
    font-size: var(--font-size-85px);
  }

  html[lang="ja"] .text-large {
    font-size: var(--font-size-72px);
  }
}

.text-small {
  font-size: var(--font-size-18px);
  line-height: 1.4;
}

.text-small-hyperlink {
  cursor: pointer;
}

@media (width >= 650px) {
  .text-small {
    font-size: var(--font-size-18px);
    line-height: 1.4;
  }
}

@media (width >= 1200px) {
  .text-small {
    font-size: var(--font-size-24px);
  }
}
