.container {
  background-color: var(--color-surface-solid-light-lighter);
}

.inner {
  position: relative;

  /**
   * ✝✝✝
   * 
   * Unsure why these properties use such strange `calc` values, but they have
   * been copied from the `rbcom-footer` web-component so that this component
   * will be horizontally-aligned with it.
   * 
   * The `rbcom-footer` web-component is actually different for RTL and LTR. The
   * margin *values* appear to be the same, but the RTL variant uses
   * `margin-right` and the LTR variant uses `margin-left`. We've simplified
   * here by using the `margin-inline-start` property which is equivalent. Read
   * more:
   * 
   * https://developer.mozilla.org/en-US/docs/Web/CSS/margin-inline-start
   */
  margin-inline-start: calc((99.99% - 20px) * 0 + 16px);
  padding-bottom: 32px;
  width: calc((99.99% - 20px) * 6 / 6 - 12px);
}

@media (width >= 650px) {
  .inner {
    /**
     * See note marked "✝✝✝" above.
     */
    margin-inline-start: calc((99.99% - 60px) * 0 + 40px);
    width: calc((99.99% - 60px) * 8 / 8 - 20px);
  }
}

@media (width >= 1000px) {
  .inner {
    /**
     * See note marked "✝✝✝" above.
     */
    margin-inline-start: calc((99.99% - 104px) * 0 + 64px);
    width: calc((99.99% - 104px) * 12 / 12 - 24px);
  }
}

@media (width >= 1600px) {
  .inner {
    /**
     * See note marked "✝✝✝" above.
     */
    margin-inline-start: calc(
      (1600px - 128px + 24px) * 0 + 64px + (100% - 1600px) / 2
    );
    width: 1472px;
  }
}

.inner select::-ms-expand {
  display: none;
}

.select {
  position: relative;
}

@media (width >= 650px) {
  .select {
    margin: 0 auto;
    width: 300px;
  }
}

@media (width >= 1000px) {
  .select {
    margin: 0 0 0 auto;
  }
}

.select-container {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-surface-glass-dark-45);
  border-radius: var(--radius-medium);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2213%22 height=%227%22 viewBox=%220 0 13 7%22%3E%3Cg fill=%22none%22 fill-rule=%22evenodd%22%3E%3Cpath d=%22M18-9v24H-6V-9%22/%3E%3Cpath fill=%22%23000F1E%22 fill-rule=%22nonzero%22 d=%22M6.865 6.86l5.98-5.686A.457.457 0 0 0 13 .833a.457.457 0 0 0-.156-.342l-.36-.35a.544.544 0 0 0-.729 0l-5.26 4.993L1.245.14a.544.544 0 0 0-.73 0l-.36.351A.457.457 0 0 0 0 .833c0 .129.056.253.156.341l5.98 5.686a.544.544 0 0 0 .729 0z%22/%3E%3C/g%3E%3C/svg%3E");
  background-position: center right;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-color: #fff;
  padding: 14px 16px 14px 48px;
  width: 100%;
  color: var(--color-text);
}

.select-container:hover {
  border-color: var(--color-surface-glass-dark-60);
}

html[dir="rtl"] .select-container {
  background-position: center left;
  padding: 14px 48px 14px 16px;
  text-align: right;
}

.flag {
  position: absolute;
  bottom: 16px;
  left: 16px;
}

html[dir="rtl"] .flag {
  right: 16px;
  left: auto;
}
