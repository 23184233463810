.container {
  display: block;
  position: fixed;
  z-index: 1001;
  background-color: var(--color-surface-solid-light-lighter);
  width: 100%;
  min-height: 10vh;
  overflow: auto;
}

.container--top {
  top: 60px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--color-surface-glass-dark-10);
}

@media (width >= 650px) {
  .container--top {
    top: 80px;
  }
}

.container--bottom {
  bottom: 0;
  border-top-width: 1px;
  border-top-style: solid;
  border-color: var(--color-surface-glass-dark-10);
}

.inner {
  position: relative;
  padding: 15px 30px;
  width: 100%;
  height: 100%;
}

.text {
  max-width: 74vw;
  color: var(--color-text-subtle);
  font-weight: 300;
  font-size: var(--font-size-12px);
  line-height: calc(15 / 12);
  font-family: var(--ff-bull);
  white-space: pre-line;
}

.text--link {
  display: inline-block;
  margin-top: 5px;
  color: var(--color-primary);
}

.button {
  display: block;
  position: absolute;
  top: 15px;
  right: 30px;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3E%3Ctitle%3EX%3C/title%3E%3Cg fill-rule='evenodd' fill='%23e02150'%3E%3Cpath id='svgstore41979e99e79857f2bd46d011f00eb7e2bar' d='M.778 3.04L3.04.78l10.747 10.747-2.262 2.262z'%3E%3C/path%3E%3Cpath d='M11.525.778l2.263 2.263L3.04 13.79.78 11.525z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E%0A");
  width: 16px;
  height: 16px;
}

@media (width >= 375px) {
  .text {
    max-width: 77vw;
  }
}

@media (width >= 768px) {
  .text {
    max-width: 90vw;
    font-size: var(--font-size-14px);
  }
}
