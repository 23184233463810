.container {
  display: flex;
  position: fixed;
  bottom: 0;
  z-index: 100;
  box-shadow: 0 -4px 16px 0 rgba(0, 15, 30, 0.2);
  background-color: var(--color-surface-solid-light-lighter);
  width: 100%;
}

.banner-wrapper {
  margin: 0 auto;
  background-color: var(--color-surface-solid-light-lighter);
  padding: 20px 16px;
  width: 100%;
  max-width: 1286px;
  color: var(--color-text);
}

@media (width >= 650px) {
  .banner-wrapper {
    padding: 20px 40px;
  }
}

@media (width >= 1000px) {
  .banner-wrapper {
    padding: 20px 64px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (width >= 650px) {
  .wrapper {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 auto;
  }
}

@media (width >= 1000px) {
  .wrapper {
    align-items: flex-start;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.meta {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.caption {
  padding-bottom: 8px;
  font-weight: 700;
  font-size: var(--font-size-16px);
  line-height: calc(19 / 16);
}

@media (width >= 650px) {
  .caption {
    font-size: var(--font-size-20px);
    line-height: calc(23 / 20);
  }
}

.description {
  padding-bottom: 16px;
  font-weight: 400;
  font-size: var(--font-size-14px);
  line-height: calc(19 / 14);
}

@media (width >= 650px) {
  .description {
    padding-bottom: 0;
    font-size: var(--font-size-16px);
    line-height: calc(22 / 16);
  }
}

.country-selection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

@media (width >= 650px) {
  .country-selection {
    margin-left: 20px;
    width: auto;
  }
}

.buttons {
  display: flex;
}

.button {
  display: inline-block;
  flex: 1 1 auto;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  border-color: var(--color-surface-glass-dark-10);
  border-radius: var(--radius-medium);
  padding: 14px 24px;
  width: 100%;
  min-width: 135px;
  color: var(--color-text);
  font-weight: 700;
  font-size: var(--font-size-16px);
  line-height: 1;
  user-select: none;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}

.button:hover {
  border-color: var(--color-primary-darker);
  background-color: var(--color-primary-darker);
  color: var(--color-text-light);
}

.button:last-child {
  margin-left: 12px;
}

@media (width >= 650px) {
  .button:last-child {
    margin-left: 20px;
  }
}

.button--cta {
  border-color: var(--color-primary);
  background-color: var(--color-primary);
  color: var(--color-text-light);
}
